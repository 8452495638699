/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {}

/* iPhone 4 ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (device-width : 800px) {
    .sidebar_wrapper.open-bar {
        width: 19% !important;
    }
}

/* Small devices (landscape tablets, small laptops) */
@media (min-width: 768px) and (max-device-width: 1024px) {
    .sidebar_wrapper.open-bar {
        width: 24% !important;

        .p-sidebar-header {
            left: 23% !important;
            top: 13% !important;
        }
    }

    .sidebar_wrapper.expanded-bar {
        width: 6% !important;

        .p-sidebar-header {
            left: 5% !important;
            top: 13% !important;
        }
    }

    .content_wrapper.open-bar {
        width: 76% !important;
    }
}

/* Medium devices (small laptops, desktops) */
@media (min-width: 1025px) and (max-width: 1350px) {
    .sidebar_wrapper.open-bar {
        width: 17.2% !important;

        .p-sidebar-header {
            left: 16.5% !important;
            top: 14% !important;
        }
    }

    .content_wrapper.open-bar {
        width: 82.8% !important;
    }

    .sidebar_wrapper.expanded-bar {
        width: 5% !important;

        .p-sidebar-header {
            left: 5% !important;
            top: 13% !important;
        }
    }

    .page_wrapper{
        height: calc(100vh - 8%) !important;
    }

}

/* Large devices (large laptops, desktops) */
@media (min-width: 1351px) {
    .content_wrapper.open-bar {
        max-width: calc(100vw - 12%);
    }

    .sidebar_wrapper.open-bar {
        width: 15% !important;

        .p-sidebar-header {
            top: 13% !important;
            left: 14vw !important;
        }
    }

    .sidebar_wrapper.expanded-bar {

        .p-sidebar-header {
            top: 9.8% !important;
            left: 2.8vw !important;
        }
    }

    .p-sidebar-left {
        width: 16rem !important;
    }

    .content_wrapper.open-bar {
        max-width: calc(100vw);
    }

    .content_wrapper.expanded-bar {
        max-width: calc(100vw - 67px);
    }

    .p-sidebar-left {
        width: 15rem !important;
    }
}



/* Samsung Galaxy Fold ----------- */
@media (min-width: 280px) and (max-width: 320px) {}



@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .signin-div {
        width: 100% !important;
    }

    .image-div {
        display: none;
    }
}
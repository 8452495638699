.font-roboto{
    font-family: 'Roboto';
}

.font-poppin{
    font-family: 'Poppins';
}

.fw-400{
    font-weight: 400;
}

.fw-500{
    font-weight: 500;
}

.fw-600{
    font-weight: 600;
}

.fw-700{
    font-weight: 700;
}

.fw-900{
    font-weight: 900;
}

.ls-1{
    letter-spacing: 1px;
}

.brd-b{
    border-bottom: 1px solid #EEF1F0;
}

.text-color-primary{
    color: #96444B;
}
.text-color-black{
    color: #000000;
}
.text-color-black2{
    color: #191919;
}
.text-color-black3{
    color: #333333;
}
.text-color-grey2{
    color: #8A8A8A;
}
.text-color-grey3{
    color: #898F9B;
}
.text-color-grey4{
    color: #4F4F4F;
}
.text-color-red{
    color: #FF7878;
}

.text-color-red2{
    color: #FC2222;
}

.text-align-center{
    text-align: center;
}

.line-height-21{
    line-height: 21px;
}
.text-color-red1{
    color: var(--incorrect-red) !important;
}


@media screen and (max-width: 1700px){
    .fs-8{
        font-size: 8px !important;
    }
    .fs-10{
        font-size: 10px !important;
    }
    
    .fs-12{
        font-size: 12px !important;
    }
    
    .fs-13{
        font-size: 13px;
    }
    
    .fs-14{
        font-size: 14px;
    }
    
    .fs-15{
        font-size: 15px;
    }
    
    .fs-16{
        font-size: 16px !important;
    }
    .fs-18{
        font-size: 18px;
    }
    .fs-30{
        font-size: 30px;
    }
    
}